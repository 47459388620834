<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Group Point'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="equipment_id_label"
                  label="Equipment :"
                  label-for="equipment_id"
                >
                  <vue-suggestion
                    id="equipment_id"
                    :items="equipment"
                    v-model="ItemEquipment"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChangeEquipment"
                    @selected="chooseEquipment"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                    size="sm"
                    required
                  >
                  </vue-suggestion>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="condition_monitoring_id_label"
                  label="Condition Monitoring :"
                  label-for="condition_monitoring_id"
                >
                  <!-- <vue-suggestion
                    id="condition_monitoring_id"
                    :items="ConditionMonitoring"
                    v-model="ItemConditionMonitoring"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChangeConditionMonitoring"
                    @selected="chooseConditionMonitoring"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                    size="sm"
                    required
                  >
                  </vue-suggestion> -->
                  <b-form-select
                    v-model="form.condition_monitoring_id"
                    :options="ConditionMonitoring"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nama_group_label"
                  label="Nama Group :"
                  label-for="nama_group"
                >
                  <b-form-input
                    id="nama_group"
                    v-model="$v.form.nama_group.$model"
                    type="text"
                    placeholder="Enter Text..."
                    :state="validateState('nama_group')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nama_group_feedback">
                    Nama Group Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="reff_label"
                  label="Reff :"
                  label-for="reff"
                >
                  <b-form-input
                    id="reff"
                    v-model="form.reff"
                    type="number"
                    placeholder="Enter Number..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="limit_label"
                  label="Limit :"
                  label-for="limit"
                >
                  <b-form-input
                    id="limit"
                    v-model="form.limit"
                    type="number"
                    placeholder="Enter Number..."
                  ></b-form-input>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/master/grouppoint')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import itemTemplate from '@/view/pages/item-template.vue'

export default {
  mixins: [validationMixin],
  name: 'addgrouppoint',
  data() {
    return {
      form: {
        nama_group: null,
        condition_monitoring_id: null,
        equipment_id: null,
        reff: null,
        limit: null,
      },
      ConditionMonitoring: [{ text: '-- PIlih Equipment --', value: null }],
      // ItemConditionMonitoring: null,
      equipment: [],
      ItemEquipment: null,
      itemTemplate,

      loading: false,
    }
  },
  validations: {
    form: {
      nama_group: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Master', route: '/master/grouppoint' },
      { title: 'Create Group Point' },
    ])
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      self.loading = true
      const nama_group = self.$v.form.nama_group.$model
      const condition_monitoring_id = self.form.condition_monitoring_id
      const equipment_id = self.form.equipment_id
      const reff = self.form.reff
      const limit = self.form.limit

      ApiService.post('/master/group_point/store', {
        nama_group: nama_group,
        equipment_id: equipment_id,
        m_condition_monitoring_id: condition_monitoring_id,
        reff: reff,
        limit: limit,
      })
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/master/grouppoint')
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    setLabel(item) {
      return item.name
    },
    inputChangeEquipment(text) {
      // your search method
      var self = this
      var url = '/master/equipment/showequipment/' + text

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var equipment = data.data

            self.equipment.splice(0, self.equipment.length)
            for (let i = 0; i < equipment.length; i++) {
              self.equipment.push({
                id: equipment[i].id_equipment,
                name: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    chooseEquipment: function(value) {
      this.form.equipment_id = value.id
      var self = this
      ApiService.get('/master/condition_monitoring/all?equipment_id=' + value.id)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var response = data.data
            if (response.length) {
              self.ConditionMonitoring.splice(0, self.ConditionMonitoring.length)
              self.ConditionMonitoring.push({
                value: null,
                text: '-- Pilih Condition Monitoring --',
              })
              for (let i = 0; i < response.length; i++) {
                self.ConditionMonitoring.push({
                  value: response[i].id_m_condition_monitoring,
                  text: response[i].nama_condition_monitoring,
                })
              }
            } else {
              self.ConditionMonitoring.splice(0, self.ConditionMonitoring.length)
              self.ConditionMonitoring.push({
                value: null,
                text: '-- Pilih Equipment Lain (Tidak ada data berdasarkan equipment tersebut) --',
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    // inputChangeConditionMonitoring(text) {
    //   // your search method
    //   var self = this;
    //   var url = "/master/condition_monitoring/show/" + text;

    //   ApiService.get(url)
    //     .then(({ data }) => {
    //       if (data.status == "ok") {
    //         var ConditionMonitoring = data.data;

    //         self.ConditionMonitoring.splice(0, self.ConditionMonitoring.length);
    //         for (let i = 0; i < ConditionMonitoring.length; i++) {
    //           self.ConditionMonitoring.push({
    //             id: ConditionMonitoring[i].id_m_condition_monitoring,
    //             name: ConditionMonitoring[i].nama_condition_monitoring,
    //           });
    //         }
    //       }
    //     })
    // .catch(response => {
    //   console.log(response);
    // });
    // },
    // chooseConditionMonitoring: function (value) {
    //   this.form.condition_monitoring_id = value.id;
    // },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
